<template lang="pug">
.loginPage-stepContainer
  .loginPage-text
    span Для того, чтобы иметь возможность в будущем входить в аккаунт с ID пациента 
    span.text-primary {{patient_id}} 
    span по номеру телефона (что куда безопаснее), укажите и подтвердите номер.
  PhoneInput(v-model="phone").loginPage-phoneInput
  BaseBtn(@click="handleSendCode" size="sm").q-mt-sm Продолжить
  MessageModal(v-model="showFailedMessage" size="lg")
    template(#body)
      .message-modal__tip._icon: icon(name="doc-progress-icon" width="14px" color="#E9E8FF")
      b В вашей карточке указан некорректный номер
      span Это нужно исправить и привязать к аккаунту корректный номер, чтобы в будущем иметь возможность входить по номеру телефона
    template(#buttons)
      BaseBtn(@click="showFailedMessage = false" size="sm") Привязать корректный номер
      BaseBtn(@click="showWhyFailedMessage = true" type="outline")
        icon(name="doc-question-icon" width="14px" color="#E9E8FF")
        span Почему у меня указан некорректный номер
  MessageModal(v-model="showWhyFailedMessage" size="lg")
    template(#body)
      b Почему у меня указан некорректный номер
      span Такое могло произойти, если администратор центра при регистрации вас в системе ошибся в вводе номера или указал, например, сразу несколько телефонов. Мы все люди и иногда можем ошибаться, поэтому в приложении предусмотрен этот сценарий, и вы можете первый раз без проблем авторизоваться по ID пациента.
      div Если у вас еще есть какой-то вопрос, то напишите нам в поддержку и мы поможем вам 👌
    template(#buttons)
      BaseBtn(@click="handlerOpenChatra" size="sm") 
        //- TODO иконка база знаний
        svg(width="15" height="16" viewbox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg")
          path(fill-rule="evenodd" clip-rule="evenodd" d="M0 5.01817C0 2.2962 2.2066 0.0895996 4.92857 0.0895996L10.0714 0.0895996C12.7934 0.0895996 15 2.2962 15 5.01817C15 7.74015 12.7934 9.94674 10.0714 9.94674H7.28571L5.04744 11.4389C4.90503 11.5339 4.71429 11.4318 4.71429 11.2606L4.71429 9.94674H4.92857C2.2066 9.94674 0 7.74015 0 5.01817ZM3.85714 4.79723C3.85714 5.27266 4.23884 5.65433 4.71428 5.66103C5.18973 5.66103 5.57143 5.27266 5.57143 4.80393C5.57143 4.32851 5.18973 3.94683 4.71428 3.94683C4.24553 3.94013 3.85714 4.32851 3.85714 4.79723ZM7.28574 5.66103C6.81029 5.65433 6.42859 5.27266 6.42859 4.79723C6.42859 4.32851 6.81699 3.94013 7.28574 3.94683C7.76118 3.94683 8.14288 4.32851 8.14288 4.80393C8.14288 5.27266 7.76118 5.66103 7.28574 5.66103ZM9.00002 4.79723C9.00002 5.27266 9.38172 5.65433 9.85716 5.66103C10.3326 5.66103 10.7143 5.27266 10.7143 4.80393C10.7143 4.32851 10.3326 3.94683 9.85716 3.94683C9.38841 3.94013 9.00002 4.32851 9.00002 4.79723Z" fill="currentColor")
          path(fill-rule="evenodd" clip-rule="evenodd" d="M14.8831 8.23248C13.8453 9.78326 12.0776 10.8043 10.0714 10.8043L7.54524 10.8043L5.52289 12.1525C4.81087 12.6272 3.85714 12.1168 3.85714 11.2611V10.7053C2.3016 10.414 0.965358 9.50027 0.116875 8.23248C0.0403525 8.57754 0 8.93621 0 9.30431C0 12.0263 2.2066 14.2329 4.92857 14.2329H7.71429L9.95256 15.7251C10.095 15.82 10.2857 15.7179 10.2857 15.5468L10.2857 14.2329H10.0714C12.7934 14.2329 15 12.0263 15 9.30431C15 8.93621 14.9596 8.57755 14.8831 8.23248Z" fill="currentColor")
        span Написать нам
</template>

<script>
import PhoneInput from '@/components/UI/inputs/PhoneInput.vue';
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
import {openChatra} from '@/services/chatra.js'

export default {
  data(){
    return {
      phone: '',
      showFailedMessage: this.scenario === 'failed',
      showWhyFailedMessage: false
    }
  },
  props: ['patient_id', 'scenario'],
  components: {PhoneInput, MessageModal},
  methods: {
    async handleSendCode(){
        console.log('phone', this.phone)
        this.$emit('setPhone', this.phone)
        this.$emit('setStep', 'StepEnterCode')
    },
    handlerOpenChatra(){
      this.showFailedMessage = false
      this.showWhyFailedMessage = false
      let userData = ''
      if (patient_id) {
          userData = `ID пациента: ${this.patient_id}`
      }
      openChatra(userData)
    }
    

  }
}
</script>