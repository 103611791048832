<template lang="pug">
MessageModal(v-bind="$attrs" v-on="$listeners" size="lg")
  template(#body)
    .message-modal__tip._icon 🙁
    span К сожалению, мы не смогли идентифицировать как пациента ЛабСтори. Чтобы попасть в личный кабинет, свяжитесь с медцентром, где вы оформляли свои данные, либо зарегистрируйтесь как новый пациент.
</template>

<script>
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
export default {
  components: {MessageModal}
}
</script>