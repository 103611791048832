<template lang="pug">
.loginPage-stepContainer
  BaseToggle(v-model="selectedAuthWay" :options="$options.toggleOptions" @input="isPasswordStep = false")
  .loginPage-field(v-if="selectedAuthWay === 'email'")
    .loginPage-fieldLabel Введите email
    FormInput(v-model="email" ref="email" type="email" name="email" placeholder="Начните вводить email" :rules="[$options.inputValidators.validEmail]" autocomplete="email").loginPage-input
  .loginPage-field(v-if="selectedAuthWay === 'phone'")
    .loginPage-fieldLabel Введите номер телефона
    PhoneInput(v-model="phone").loginPage-phoneInput
  .loginPage-field(v-if="isPasswordStep")
    .loginPage-fieldLabel Введите пароль
    FormInput(v-model="password" type="password" ref="password" name="password" placeholder="Пароль от кабинета").loginPage-input
    .text-grey-4.relative-position.cursor-pointer(@click="forgotPasswordHandler") Я забыл пароль
  AgreementLink
  BaseBtn(v-if="isPasswordStep" @click="loginUserHandler" icon="next-icon2" :iconSize="10" size="sm").q-mt-md Войти
  BaseBtn(v-else @click="checkUserHandler" icon="next-icon2" :iconSize="10" size="sm").q-mt-md Продолжить
  BaseBtn(@click="$emit('setStep', 'StepEnterId')" icon="next-icon2" :iconSize="10" size="sm" type="transparent") Войти через ID
  NotExistPhoneEmailModal(v-model="showNotExistMessage" @toStepEnterId="$emit('setStep', 'StepEnterId')")
  MessageModal(v-model="showHasDoubleMessage" size="lg")
    template(#body)
      span В нашей базе есть несколько пользователей привязанных к {{currentDeclination}}. 
      span Для безопасности ваших данных и данных других пользователей, сейчас вы можете авторизоваться только с помощью ID пациента.
    template(#buttons)
      BaseBtn(@click="$emit('setStep', 'StepEnterId')" size="sm") Войти по ID пациента
</template>

<script>
import AgreementLink from './AgreementLink.vue';
import BaseToggle from '@/components/UI/BaseToggle.vue';
import PhoneInput from '@/components/UI/inputs/PhoneInput.vue';
import FormInput from '@/components/UI/inputs/FormInput.vue';
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';

import NotExistPhoneEmailModal from './modals/NotExistPhoneEmailModal.vue';
import {validEmail} from '@/utils/validators';
import {showClientError} from '@/utils/showNotify.js'

export default {
  toggleOptions: [
    {value: 'phone', label: 'Телефон', declination: 'этому номеру телефона'},
    {value: 'email', label: 'Почта',  declination: 'этой почте'},
  ],
  inputValidators: {validEmail},

  components: {
    BaseToggle,
    PhoneInput,
    AgreementLink,
    FormInput,
    NotExistPhoneEmailModal,
    MessageModal
  },

  data(){ 
    return {
      phone: '',
      email: '',
      password: '',
      selectedAuthWay: this.$options.toggleOptions[0].value,
      showNotExistMessage: false,
      showHasDoubleMessage: false,
      isPasswordStep: false,
    }
  },
  methods: {
    async checkUserHandler(){
      const targetInput = this.$refs.email?.$refs.input
      //  TODO валидация на клиенте реализована только для email!
      if (targetInput){
        targetInput.validate()
        if (targetInput.hasError) {
          return
        }
      }
        const checkUserResult = await this.$api.auth.checkUser({
          [this.selectedAuthWay]: this[this.selectedAuthWay]
        })

        console.log(checkUserResult)

        if (!checkUserResult.user_exist) {
          this.userIsNotExist()
          return
        }

        if (checkUserResult.only_patient_id) {
          this.userHasDouble()
          return
        }

        if (checkUserResult.has_password) {
          this.isPasswordStep = true
        } else {
          if (this.selectedAuthWay === 'email') {
            this.$emit('setEmail', this.email)
          } else {
            this.$emit('setPhone', this.phone)
          }
          this.$emit('setScenario', 'hasNotPassword')
          this.$emit('setStep', 'StepEnterCode')
        }
      
    },
    async loginUserHandler(){
      const payload = {
        authType: 'login',
        authData: {
          [this.selectedAuthWay]: this[this.selectedAuthWay],
          password: this.password
        },
      }
      // TODO второй этап рефакторинга - почистить чушь из сторы
      const authRes = await this.$store.dispatch('auth/authUser', payload)
      if (authRes) this.$router.push({ name: 'indexPage' });
    },
    forgotPasswordHandler(){
      if (this.selectedAuthWay === 'email') {
        this.$emit('setEmail', this.email)
      } else {
        // this.$emit('setPhone', this.phone.replace(/[^0-9]/g, ''))
        this.$emit('setPhone', this.phone)
      }
      this.$emit('setScenario', 'forgotPassword')
      this.$emit('setStep', 'StepEnterCode')
    },
    userIsNotExist(){
      this.showNotExistMessage = true
    },
    userHasDouble(){
      this.showHasDoubleMessage = true
    }
  },
  computed: {
    currentDeclination(){
      const selectedOption = this.$options.toggleOptions.find( item => item.value === this.selectedAuthWay)
      return selectedOption.declination
    }
  }
}

</script>