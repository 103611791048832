<template lang="pug">
.loginPage-stepContainer
  .loginPage-text(style="margin-bottom: 18px") ID указан в результатах ваших анализов
  .loginPage-field
    .loginPage-fieldLabel Введите ваш номер ID
    FormInput(:value="patient_id" @input="$emit('setId', $event)" placeholder="ID пациента" name="userid").loginPage-input
  AgreementLink
  BaseBtn(@click="handleCheckUser" icon="next-icon2" :iconSize="10" size="sm").q-mt-md Продолжить
  BaseBtn(@click="goToStepLogin" icon="next-icon2" :iconSize="10" size="sm" type="transparent") Войти через телефон/email
  NotExistIDModal(v-model="showNotExistMessage")
  MessageModal(v-model="showHasPasswordMessage" size="lg")
    template(#body)
      .weight-medium У вас есть пароль
      span Пожалуйста зайдите через пароль
    template(#buttons)
      BaseBtn(@click="goToStepLogin" icon="next-icon2" :iconSize="10" size="sm" ) Хорошо
</template>

<script>
import FormInput from '@/components/UI/inputs/FormInput.vue';
import AgreementLink from './AgreementLink.vue';
import NotExistIDModal from './modals/NotExistIDModal.vue';
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
import { checkUser } from '@/api/auth.js';

export default {
  data(){
    return { 
      showNotExistMessage: false,
      showHasPasswordMessage: false
    }
  },
  props: ['patient_id'],
  components: {FormInput, MessageModal, AgreementLink, NotExistIDModal },
  methods: {
    async handleCheckUser(){
      const {patient_id} = this
      const checkUserResult = await checkUser({patient_id})
      console.log(checkUserResult)
        if (checkUserResult.user_exist) {
          if (checkUserResult.has_password) {
            this.showHasPasswordMessage = true
          } else {
            this.$emit('setId', patient_id)
            this.$emit('setStep', 'StepConfirmId')
          }
        } else {
          this.showNotExistMessage = true
        }

    },
    goToStepLogin(){
      this.$emit('setId', '')
      this.$emit('setStep', 'StepLogin')
    }
  }
}
</script>