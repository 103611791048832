<template lang="pug">
.loginPage-stepContainer
  .loginPage-text.q-mb-sm
    span К вашему ID пациента уже привязан e-mail 
    span.text-primary {{email}}
    br
    span Для того, чтобы войти в приложение, подтвердите привязанный e-mail. На указанный e-mail мы вышлем проверочный код.
  BaseBtn(@click="$emit('setStep', 'StepEnterCode')" icon="next-icon2" :iconSize="10" size="sm" ) Продолжить

</template>

<script>
export default {
  props: ['email']
}
</script>
