<template lang="pug">
.loginPage-stepContainer
  FormInput(v-model="name" :rules="[$options.inputValidators.validRequired]" type="text" ref="name" name="name" placeholder="Введите имя").loginPage-input
  FormInput(v-model="surname" :rules="[$options.inputValidators.validRequired]" type="text" ref="surname" name="surname" placeholder="Введите фамилию").loginPage-input
  PhoneInput(v-model="phone").loginPage-phoneInput
  AgreementCheckbox(v-model="acceptCheckbox")
  BaseBtn(@click="checkUserHandler" :disabled="!acceptCheckbox" type="outline" size="sm" icon="next-icon2" :iconSize="10" ) Выслать проверочный код
</template>

<script>
import AgreementCheckbox from './AgreementCheckbox.vue';
import BaseToggle from '@/components/UI/BaseToggle.vue';
import PhoneInput from '@/components/UI/inputs/PhoneInput.vue';
import FormInput from '@/components/UI/inputs/FormInput.vue';
import {validRequired} from '@/utils/validators';
import {showClientError} from '@/utils/showNotify.js'

export default {
  toggleOptions: [
    {value: 'phone', label: 'Телефон', declination: 'этому номеру телефона'},
    {value: 'email', label: 'Почта',  declination: 'этой почте'},
  ],
  inputValidators: {validRequired},

  components: {
    BaseToggle,
    PhoneInput,
    AgreementCheckbox,
    FormInput,
  },

  data(){ 
    return {
      phone: '',
      name: '',
      surname: '',
      acceptCheckbox: false
    }
  },

  methods: {
    async checkUserHandler(){
      const nameInput = this.$refs.name?.$refs.input
      const surnameInput = this.$refs.surname?.$refs.input
      //  TODO валидация на клиенте реализована только для name / surename (нужно и для телефона)!
      nameInput.validate()
      surnameInput.validate()
      if (nameInput.hasError || surnameInput.hasError) {
        return
      }
      const checkUserResult = await this.$api.auth.checkUser({
        // debug: true,
        phone: this.phone
      })

      if (checkUserResult.user_exist) {
        showClientError('Этот пользователь уже зарегистрирован. Пожалуйста выполните вход')
        this.$emit('setStep', 'StepLogin');
        return
      } else {
        this.$emit('setName', this.name)
        this.$emit('setSurname', this.surname)
        this.$emit('setPhone', this.phone)
        this.$emit('setScenario', 'register')
        this.$emit('setStep', 'StepEnterCode');
      }
    }
  }
 
}

</script>