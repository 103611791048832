<template lang="pug">
MessageModal(v-bind="$attrs" v-on="$listeners" size="lg")
  template(#body)
    .message-modal__tip._icon 🙁
    span Мы не смогли найти пользователя с таким номером.
    span Возможно он не привязан к аккаунту. Попробуйте ввести другой номер, либо войдите с помощью лектронной почты пациента.
  template(#buttons)
    BaseBtn(@click="$emit('toStepEnterId')" size="sm") Войти по ID пациента
    BaseBtn(@click="$emit('input', false)" type="transparent") Назад
</template>

<script>
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
export default {
  components: {MessageModal}
}
</script>