var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('div',{class:{ 'password-change__input form-input': true, 
  'form-input--empty': _vm.checkError(_vm.$refs.newPassword) }},[_c('label',{staticClass:"form-label"},[_vm._v("Новый пароль")]),_c('q-input',{ref:"newPassword",attrs:{"rounded":"","rules":_vm.rules,"placeholder":"Введите новый пароль","type":_vm.visibleNewPass ? 'password' : 'text',"borderless":false},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}},[_c('q-icon',{staticClass:"cursor-pointer pwd-icon",attrs:{"name":_vm.visibleNewPass ? 'visibility_off' : 'visibility'},on:{"click":function($event){_vm.visibleNewPass = !_vm.visibleNewPass}}}),_c('icon',{class:{
          'form-input__icon': true,
          'form-input__icon-valid': !_vm.checkError(_vm.$refs.newPassword),
          'form-input__icon-invalid': _vm.checkError(_vm.$refs.newPassword),
        },attrs:{"name":_vm.iconNameNewPass}})],1),_c('div',{staticClass:"password-change__meter"},[_c('passwordMeter',{attrs:{"password":_vm.newPassword},on:{"score":_vm.onScore}}),_c('div',{staticClass:"password__strength",class:_vm.newPassword ? _vm.passwordStrength : ''},_vm._l((3),function(index){return _c('span',{key:index})}),0),(_vm.passwordStrengthText[_vm.passwordStrength] && _vm.newPassword)?_c('div',{staticClass:"password-change__meter-desk",domProps:{"innerHTML":_vm._s(_vm.passwordStrengthText[_vm.passwordStrength])}}):_vm._e()],1)],1),_c('div',{class:{ 'password-change__input form-input': true, 'form-input--empty': _vm.checkError(_vm.$refs.repeatPassword) }},[_c('label',{staticClass:"form-label"},[_vm._v("Повторите пароль")]),_c('q-input',{ref:"repeatPassword",attrs:{"rounded":"","rules":[_vm.newPasswordValidate ].concat( _vm.rules),"placeholder":"Повторите пароль","type":_vm.visibleNewPass ? 'password' : 'text',"borderless":false},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}},[_c('q-icon',{staticClass:"cursor-pointer pwd-icon",attrs:{"name":_vm.visibleNewPass ? 'visibility_off' : 'visibility'},on:{"click":function($event){return _vm.changePwd('repeatPassword')}}}),_c('icon',{class:{
          'form-input__icon': true,
          'form-input__icon-valid': !_vm.checkError(_vm.$refs.repeatPassword),
          'form-input__icon-invalid': _vm.checkError(_vm.$refs.repeatPassword),
        },attrs:{"name":_vm.iconNameRepeatPass}})],1)],1),_c('BaseBtn',{attrs:{"disabled":_vm.someError,"icon":"next-icon2","iconSize":10,"size":"sm"},on:{"click":_vm.setNewPassword}},[_vm._v("Войти")]),_c('ModalPasswordSetted',{model:{value:(_vm.isShowModalPasswordSetted),callback:function ($$v) {_vm.isShowModalPasswordSetted=$$v},expression:"isShowModalPasswordSetted"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }