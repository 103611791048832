// const onlyLetters = /[a-zA-ZА-Яа-я ]*/;
// const onlyLettersAndHyphen = /[a-zA-ZА-Яа-я -]*/;
// const onlyNumber = /[0-9]*/;
// const phonePattern = /^\d[\d\(\)\ -]{6,14}\d$/;
// const minLength = 1;
// const maxLength = 20;
// const passwordPattern = /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

// public inputRules = {
//   maxMinlength: (val: string) =>
//     (val.length > this.minLength && val.length <= this.maxLength) ||
//     `мин ${this.minLength + 1}, макс ${this.maxLength} символов`,
//   required: (val: string) => val?.toString()?.length || 'Обязательно к заполнению',
//   validEmail: (val: string) => this.emailPattern.test(val) || 'Неверный адрес электронной почты',
//   validPhone: (val: string) => this.phonePattern.test(val) || 'Неверный номер телефона',
//   validPassword: (val: string) =>
//     this.passwordPattern.test(val) || `Пароль должен содержать: буквы и цифры, заглавную букву, минимум 8 символов`,
// };

const emailPattern =
  /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
export const validEmail = (val) => emailPattern.test(val) || 'Неверный адрес электронной почты';
export const validRequired = (val) => !!val || 'Обязательно к заполнению';
