import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { IAuthApi } from '@/interfaces/auth.interface';
let AuthMixin = class AuthMixin extends Vue {
    getFiledType(value) {
        return value.includes('@') ? IAuthApi.CheckUserParamsType.EMAIL : IAuthApi.CheckUserParamsType.PHONE;
    }
    convertSendData(codeData, checkedValue) {
        return {
            codeData,
            checkedValue,
        };
    }
    changeStep(value) {
        this.$store.commit('auth/setPropertyInStore', { name: 'currentAuthStep', value });
    }
    get userAccountInfo() {
        return this.$store.state.auth.userAccountInfo;
    }
};
AuthMixin = __decorate([
    Component({})
], AuthMixin);
export default AuthMixin;
