<template lang="pug">
.loginPage-stepContainer
  p.loginPage-text Нам нужны дополнительные сведения, чтобы подтвердить ваш ID пациента
  FormInput(:rules="[$options.inputValidators.validRequired]" v-model="fio" label="Фамилия Имя Отчество" placeholder="Иванов Иван Иванович" ref="fio" name="fio").loginPage-input
  //- FormInput(v-model="research_date" label="Дата" placeholder="Дата" name="patient-date").loginPage-input
  InputDate(
      :value="research_date"
      @change-value="research_date = $event"
      format="yyyy-MM-dd"
      :tempVarFormatDataToServer="true"
      label="Дата исследования" 
      placeholder="__.__.____").loginPage-input
  BaseBtn(@click="handleLoginById" size="sm" ) Подтвердить
  MessageModal(v-model="showWrongValuesMessage" size="lg")
    template(#body)
      .message-modal__tip._icon 🙁
      .weight-medium Мы не смогли найти пользователя с такими данными
      span Возможно вы ошиблись в данных

</template>

<script>
import FormInput from '@/components/UI/inputs/FormInput.vue';
import InputDate from '@/components/InputDate.vue';
import { loginById } from '@/api/auth.js';
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
import {validRequired} from '@/utils/validators';
import { showClientError } from '@/utils/showNotify.js';


export default {
  inputValidators: {validRequired},
  data(){
    return {
      fio: null,
      research_date: null,
      // fio: 'Петров Петя Петрович',
      // research_date: '2001-12-25',
      showWrongValuesMessage: false
    }
  },
  props: ['patient_id'],
  components: {FormInput, InputDate, MessageModal},
  methods: {
    async handleLoginById(){
      if (!this.validateFields()) return
      try {
        const {fio, research_date, patient_id} = this
        const patientLoginResult = await loginById({patient_id, fio, research_date})
        const {phone, email, is_phone_failed, patient_token} = patientLoginResult
        
        this.$emit('setToken', patient_token)
        this.$emit('setPhone', phone)
        this.$emit('setEmail', email)

        console.log('email',email)
        if (!phone && !is_phone_failed) {
          this.$emit('setScenario', 'none') // Сценарий 1 (нет номера)
          this.$emit('setStep', email ? 'StepConfirmEmail' : 'StepBindPhone')  
        } 
        if (!phone && is_phone_failed) {
          this.$emit('setScenario', 'failed') // Сценарий 2 (номер неккоректен)
          this.$emit('setStep', email ? 'StepConfirmEmail' : 'StepBindPhone')  
        } 
        if (phone) {
          this.$emit('setScenario', 'correct') // Сценарий 3 (номер корректный)
          this.$emit('setStep', 'StepConfirmPhone')
        } 
      } catch {
        this.showWrongValuesMessage = true
      }

    },
    validateFields(){
      const fioInput = this.$refs.fio?.$refs.input
      fioInput.validate()
      if (fioInput.hasError) return false
      if (this.research_date === null) {
        showClientError('Заполните дату. Это обязательное поле')
        return false
      }
      return true
    }
  }
}
</script>