<template lang="pug">
.loginPage-stepContainer
  p.loginPage-text
    span Введите код, который мы выслали на {{way.text}} 
    span.text-primary {{way.value}}
  CodeInput(v-model="code" @codeComplete="handler")
  BaseBtn(@click="handler" size="sm").q-mt-sm.q-mb-md Подтвердить
  .loginPage-text(v-if="isBlockedSendCode") Вы сможете отправить следующий код через {{ blockedSendCodeTime }} секунд
  .loginPage-repeatCode(v-else @click="handleSendCode") Код не приходит.
</template>

<script>
import CodeInput from '@/components/UI/inputs/CodeInput.vue';
import { sendCode, confirmCode, loginUser, passwordCheckCode, forgotPassword } from '@/api/auth.js';

export default {
  data(){
    return {
      code: '',
      blockedSendCodeTime: 30,
      isBlockedSendCode: false,
    }
  },
  props: ['patient_token', 'phone', 'email', 'isEmailConfirmed' , 'scenario', 'name', 'surname'],
  components: {CodeInput},
  computed: {
    way() {
      const {email, isEmailConfirmed, phone} = this
      const isEmailWay = this.scenario !== 'register' && this.scenario !== 'correct' && email && !isEmailConfirmed
      return {
        value: isEmailWay ? email : phone,
        key: isEmailWay ? 'email' : 'phone',
        text: isEmailWay ? 'e-mail' : 'номер',
        // approve: isEmailWay ? 'approve_email' : 'approve_phone'
      }
    },
    handler(){
      const scenariosHandlers = {
        register: this.handleRegistrationConfirm,
        hasNotPassword: this.loginWithoutPassword,
        forgotPassword: this.handleCheckCode,
      }
      return scenariosHandlers[this.scenario] || this.handleConfirmCode
    }
  },
  mounted() {
    this.handleSendCode()
  },
  methods: {
    async handleSendCode(){
      const {key, value, approve} = this.way
      // const sendCodeRequest = await sendCode({[key]: value, debug: true})
      const sendCodeRequest = this.scenario === 'forgotPassword' ? await forgotPassword({[key]: value}) : await sendCode({[key]: value})
      if (sendCodeRequest.statusText === "OK") {
        this.blockSendCode()
      }
    },
    async handleConfirmCode(){
      const {code, patient_token} = this
      const {key, value} = this.way
      const res = await confirmCode({[key]: value, code}, patient_token)
      if (res) {
        if (key === 'phone') {
          this.$emit('setStep', 'StepSuccessBind')
          this.$store.dispatch('auth/authUser_new', res);
        }
        if (key === 'email') {
          this.$emit('setIsEmailConfirmed', true)
          this.$emit('setStep', 'StepBindPhone')
        }
      }
    },
    async handleCheckCode(){
      const {code} = this
      const {key, value} = this.way
      const res = await passwordCheckCode({[key]: value, code})
      if (res.status === 200) {
          this.$emit('setCode', this.code)
          this.$emit('setStep', 'StepSetPassword')
      }
    },
    async loginWithoutPassword(){
      const {code} = this
      const {key, value} = this.way
      const res = await loginUser({[key]: value, code})
      if (res) {
          this.$store.dispatch('auth/authUser_new', res);
          this.$emit('setStep', 'StepSuccessBind')
        }
    },
    async handleRegistrationConfirm(){
      const res = await this.$api.auth.registerUser({
          name: this.name,
          surname: this.surname,
          code: this.code,
          phone: this.phone,
        }
      )
      this.$store.dispatch('auth/authUser_new', res);
      this.$emit('setStep', 'StepSuccessBind')
      // this.$router.push('/')
      console.log(res)
    },


  
  blockSendCode(){
    this.isBlockedSendCode = true;
    const isBlockedSendCodeTimeTimerId = setInterval(() => {
      this.blockedSendCodeTime -= 1;
      if (this.blockedSendCodeTime === 0){
        this.isBlockedSendCode = false;
        this.blockedSendCodeTime = 30;
        clearInterval(isBlockedSendCodeTimeTimerId);
      }
    }, 1000); 
  }
  }
}
</script>