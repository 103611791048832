<template lang="pug">
MessageModal(v-bind="$attrs" v-on="$listeners" size="lg")
  template(#body)
    .weight-medium Отлично! Пароль установлен 🔒
    span Теперь вы можете входить в аккаунт приложения LabStory под номером и установленным паролем
  template(#buttons)
    BaseBtn(@click="$router.push('/')" size="sm") Перейти в приложение
</template>

<script>
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
export default {
  components: {MessageModal}
  }
</script>
<!-- TODO ADD ALL MODAL FROM THIS PAGE TO THIS FOLDER-->