<template lang="pug">
.loginPage-stepContainer
  .loginPage-text.q-mb-sm
    span К вашему ID пациента уже привязан номер телефона 
    span.text-primary {{phone}}
    br
    span Для того, чтобы войти в приложение, подтвердите привязанный номер телефона. На указанный номер мы вышлем смс с проверочным кодом.
  BaseBtn(@click="$emit('setStep', 'StepEnterCode')" icon="next-icon2" :iconSize="10" size="sm" ) Продолжить

</template>

<script>
export default {
  props: ['phone']
}
</script>