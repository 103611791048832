import { render, staticRenderFns } from "./AgreementCheckbox.vue?vue&type=template&id=67b46246&lang=pug&"
var script = {}
import style0 from "./AgreementCheckbox.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCheckbox});
