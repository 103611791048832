<template>
  <div class="auth-page__content-toggles" style="margin-bottom: 30px;">
    <div class="form-toggle form-toggle--with-label">
      <q-toggle
        v-model="isLabstoryPatient"
        @input="onModeChange"
        label="Я пациент ЛабСтори или Панорама Мед"
      />
    </div>
    <p class="auth-page__content-toggles-desk q-mt-md" v-if="isLabstoryPatient">
      Вы уже проходили обследования в ЛабСтори или Панорама Мед.
      <br />Можно авторизоваться по номеру или по почте.
    </p>
  </div>
</template>

<script>
export default {
  props: ['initialState'],
  data() {
    return {
      isLabstoryPatient: this.$store.state.auth.isLabstoryPatient
    }
  },
  methods: {
    onModeChange(){
      // this.$router.push('auth?pageMode=login&isLabstoryPatient=1')
    }
  }
}
</script>