// TODO Refacotr
<template>
  <div class="tabs">
    <q-tabs :value="activeTab" @input="tabsChange" class="tabs__header" align="left">
      <q-tab v-for="(tab, index) in data" :name="tab.name" :label="tab.label" :key="index" >
        <div class="tabs__badge" v-if="tab.badge">{{tab.badge}}</div>
      </q-tab>
    </q-tabs>
    <q-tab-panels keep-alive :value="activeTab" class="tabs__body" animated>
      <slot></slot>
    </q-tab-panels>
  </div>
</template>

<script>
// TODO v-model
export default { 
  props: ['data', 'onTabChange', 'activeTab'],
  methods: {
    tabsChange(activeTab) {
      if (this.onTabChange) this.onTabChange(activeTab);
      this.$emit('tabChange', activeTab)
    }
  }
}
</script>

<style lang="scss">

.tabs__badge {
  height: 23px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 15px rgba(22, 20, 44, 0.06);
  border-radius: 15px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  position: absolute;
  top: 0;
  transform: translate(60px, -50%);
  z-index: 111;
  background: $accent-color;
  color: #FFFFFF;
  transition: .2s;
  .q-tab--active & {
    background: #FFFFFF;
    color: $accent-color;
  }

}

.tabs {
    .q-tab-panel {
    padding: 0;
  }
  .form-input {

.q-input .q-field__control {
    height: 50px;
    min-height: 50px
  }

  
  .q-field__marginal {
    height: 50px;
  }


  }
    .form-select .q-field__inner .q-field__native {
    padding-left: 10px;
  }

  

  .form-input input {
    height: 50px;
  }
  &__header {
    box-sizing: border-box;
    width: fit-content;
    padding: 6px;
    margin-bottom: 40px;
    border: 1px solid $light-stroke;
    border-radius: 22px;
    @include media-down($breakpoint-md) {
      padding: 0;
      border-radius: 18px;
      margin-bottom: 24px;
    }
    .q-tabs__content {
      gap: 5px;
      overflow: visible;
    }
    .q-tab {
      display: flex;
      align-items: center;
      height: 44px;
      min-height: 44px;
      padding: 0px 25px;
      border-radius: 18px;
      font-weight: 500;
      font-size: 13px;
      color: #424056;
      @include media-down($breakpoint-sm) {
        height: auto;
        
      }

      .q-tab__label {
        text-transform: none;
      }

      @include media-up($breakpoint-sm) {
        &:hover {
          background-color: $tabs-hover;
          color: #424056;
        }
      }


      .q-tab__content {
        align-self: auto;
      }

      .q-focus-helper {
        display: none;
      }

      .q-ripple {
        display: none;
      }
    }

    .q-tab--active {
      color: #fff;
      background-color: $accent-color;

      .q-tab__indicator {
        display: none !important;
      }
    }
  }

  &__body {
    padding-right: 15px;
    padding-bottom: 20px;
    padding-left: 15px;
    margin-left: -15px;
    margin-right: -15px;
    background-color: transparent !important;

    .q-panel {
      padding-right: 15px;
      padding-bottom: 20px;
      padding-left: 15px;
      margin-left: -15px;
      margin-right: -15px;
    }

    @include media-down($breakpoint-sm) {
      margin: 0;
      padding: 0;

      .q-panel {
        margin: 0;
        padding: 0;
      }
    }
  }
}
</style>
