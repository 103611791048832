<template lang="pug">
.loginPage-stepContainer
  .loginPage-text Отлично!<br>У вас все получилось 👌
  .loginPage-text(v-if="scenario === 'none' || scenario === 'failed'") Теперь вы можете входить в аккаунт приложения LabStory под номером {{phone}}
  .loginPage-text(v-if="scenario === 'correct'") Добро пожаловать в наше приложение ❤️
  BaseBtn(@click="$emit('setStep', 'StepSetPassword')" icon="lock2" :iconSize="15" size="sm" wide).q-mt-md Установить пароль
  BaseBtn(@click="$router.push('/')" size="sm" type="outline" wide) Перейти в приложение
  //- .loginPage-infoText Авторизуясь, вы принимаете условия «Пользовательского соглашения»

</template>

<script>

export default {
  props: ['scenario', 'phone'],
  methods: {

  }
}
</script>