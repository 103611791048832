import store from '@/store';

export const openChatra = (notes) => {
  window.Chatra.updateIntegrationData({ notes });
  window.Chatra.show();
  window.Chatra.openChat();
};

export const stringifyUserInfo = () => {
  const { patient_id, name, surname, patronymic, phone, email } = store.state.personalArea.patient;
  let userData = '';
  if (patient_id) {
    userData = `ID лабстори: ${patient_id}`;
  }
  if (surname || name || patronymic) {
    userData = `${userData}\nФИО: ${surname} ${name} ${patronymic}`;
  }
  if (email) {
    userData = `${userData}\nE-mail: ${email}`;
  }
  if (phone) {
    userData = `${userData}\nТелефон: ${phone}`;
  }
  return userData;
};
