<!-- temp this page is login by ID, after refactoring will be main auth page / old will removed -->
<template lang="pug">
.loginPage
  .loginPage-title {{currentStepTitle}}
  PanoramaSwitcher(:initialState="true")
  BaseTabs(:data="$options.authTypeTabs" @tabChange="activeAuthTab = $event" :activeTab="activeAuthTab").tariffs-tabs
  .loginPage-form
    Component(:is="currentStepComponent" 
      v-bind="{patient_id, patient_token, phone, scenario, isEmailConfirmed, email, name, surname, codeSms}"
      v-on="eventObject")
  //- debug select step
  //- .loginPage-debugSelectStep
  //-   select(v-model="step"): option(v-for="(value, name) in steps" :value="name") {{name}}
  //-   pre scenario: {{scenario}}
  //-   pre phone: {{phone}}
  //-   pre email: {{email}}
</template>

<script>


import StepLogin from './StepLogin.vue';
import StepRegister from './StepRegister.vue';

import StepEnterId from './StepEnterId.vue';
import StepConfirmId from './StepConfirmId.vue';
import StepEnterCode from './StepEnterCode.vue';
import StepBindPhone from './StepBindPhone.vue';
import StepSuccessBind from './StepSuccessBind.vue';
import StepConfirmPhone from './StepConfirmPhone.vue';
import StepConfirmEmail from './StepConfirmEmail.vue';
import StepSetPassword from './StepSetPassword.vue';

import PanoramaSwitcher from '@/components/PanoramaSwitcher.vue';
import BaseTabs from '@/components/BaseTabs.vue';

const steps = {
  StepLogin: {
    title: 'Добро пожаловать!',
    component: StepLogin,
  },
  StepRegister: {
    title: 'Добро пожаловать!',
    component: StepRegister,
  },

  StepEnterId: {
    title: 'Ваш ID пациента',
    component: StepEnterId,
  },
  StepConfirmId: {
    title: 'Подтвердите ID',
    component: StepConfirmId,
  },
  StepEnterCode: {
    title: 'Введите код',
    component: StepEnterCode,
  },
  StepBindPhone: {
    title: 'Привязка номера к аккаунту',
    component: StepBindPhone,
  },
  StepSuccessBind: {
    title: 'Номер привязан',
    component: StepSuccessBind,
  },
  StepConfirmPhone: {
    title: 'Подтвердите номер телефона',
    component: StepConfirmPhone,
  },
  StepConfirmEmail: {
    title: 'Подтвердите почту',
    component: StepConfirmEmail,
  },
  StepSetPassword: {
    title: 'Установите пароль',
    component: StepSetPassword,
  },

}

export const authTypeTabs = [
  {
    name: 'StepRegister',
    label: 'Регистрация',
  },
  {
    name: 'StepLogin',
    label: 'Войти',
  }
];
export default {
  authTypeTabs,
  data(){
    return {
      activeAuthTab: authTypeTabs[0].name,
      step: 'StepRegister',
      steps,
      scenario: null, // null, 'none', 'failed', 'correct' - Сценарии корректности номера телефона 
      isEmailConfirmed: null,

      patient_id: '',
      patient_token: '',
      phone: '',
      email: '',

      name: '',
      surname: '',
      codeSms: '',

      // TODO .sync ?
      // TODO  store ?
      // TODO  router ?
      eventObject: {
        setStep: (e) => {this.step = e},
        setId: (e) => {this.patient_id = e},
        setScenario: (e) => {this.scenario = e},
        setPhone: (e) => {this.phone = e},
        setEmail: (e) => {this.email = e},
        setToken: (e) => {this.patient_token = e},
        setIsEmailConfirmed: (e) => {this.isEmailConfirmed = e},

        setName: (e) => {this.name = e},
        setSurname: (e) => {this.surname = e},
        setCode: (e) => {this.codeSms = e},
      }
    }
  },
  components: {PanoramaSwitcher, BaseTabs},
  computed: {
    currentStepComponent(){
      return this.steps[this.step].component
    },
    currentStepTitle(){
      return this.steps[this.step].title
    },
  },
  watch:{
    activeAuthTab(){
      if (this.step !== this.activeAuthTab){
        this.step = this.activeAuthTab
      }
    },
    step(){
      if (this.step !== this.activeAuthTab && authTypeTabs.find(item => item.name === this.step)){
        this.activeAuthTab = this.step
      }
    }
  },
  mounted() {
    // TODO
    // if (this.$store.getters['auth/isLoggedIn']) {
    //   this.$store.dispatch('auth/logOut');
    // }
  }
}
</script>

<style lang="scss">
.loginPage {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  // height: 100%;
  justify-content: center;
  @include media-up($breakpoint-md){
    // margin-left: 100px;
  }
}

.loginPage-title {
  font-size: 36px;
  line-height: 130%;
  color: $grey-1;
  font-weight: 500;
  margin-bottom: 30px;
}
.loginPage-subtitle {
  color: $grey-1;
  margin-bottom: 50px;
}

.loginPage-form {
  background: #FFFFFF;
  box-shadow: 0px 4px 15px rgba(22, 20, 44, 0.06);
  border-radius: 26px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  @include media-up($breakpoint-md){
    width: 560px;
    padding: 20px;
  }
}


.loginPage-text{
  font-size: 14px;
  line-height: 18px;
  color: $grey-1
}



.loginPage-stepContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px
}

.loginPage-debugSelectStep {
  position: fixed;
  top: 50px;
  right: 50px;
  z-index: 1;
}

.loginPage-repeatCode {
  text-decoration: underline;
  color: $primary;
  cursor: pointer;
}

.loginPage-field {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  & + & {
    margin-top: 0px;
  }
}

.loginPage-input{
  width: 100%;
}

.loginPage-phoneInput {
  width: 100%;
  margin-top: 8px !important;
  margin-bottom: 6px !important;
}


.loginPage-fieldLabel {
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #424056;
}

.loginPage-infoText{
  color: $grey-4;
  font-size: 12px;
  line-height: 15px;
  a {
    color: $grey-4;
  }
}
</style>


