import { __decorate } from "tslib";
import ModalPasswordSetted from './modals/ModalPasswordSetted.vue';
import passwordMeter from 'vue-simple-password-meter';
import { Component, Prop } from 'vue-property-decorator';
;
import { IAuth, IAuthForOtherUser } from '@/interfaces/auth.interface';
import AuthMixin from '@/mixins/auth-mixin';
import StatusIndicator from '@/components/StatusIndicator.vue';
import { IRouter } from '@/interfaces/router.interface';
var ROUTE_NAME = IRouter.ROUTE_NAME;
import { IUserCard } from '@/interfaces/personal-area.interface';
import { confirmCode, passwordCheckCode, passwordReset } from '@/api/auth.js';
import { changePatient } from '@/api/patient.js';
let StepSetPassword = class StepSetPassword extends AuthMixin {
    constructor() {
        super(...arguments);
        this.someError = null;
        this.visibleNewPass = true;
        this.showNewPasswordInputs = true;
        this.rules = [];
        this.repeatPassword = '';
        this.passwordStrength = '';
        this.passwordStrengthText = {
            [IUserCard.PasswordStrength
                .RISKY]: `<h6>Слабый пароль</h6><p>Пароль должен быть не менее 8 символов и содержать заглавные и строчные буквы, цифры и специальные символы</p>`,
            [IUserCard.PasswordStrength
                .QUESSABLE]: `<h6>Средний пароль</h6><p>Пароль должен быть не менее 8 символов и содержать заглавные и строчные буквы, цифры и специальные символы</p>`,
            [IUserCard.PasswordStrength
                .WEAK]: `<h6>Средний пароль</h6><p>Пароль должен быть не менее 8 символов и содержать заглавные и строчные буквы, цифры и специальные символы</p>`,
            [IUserCard.PasswordStrength
                .SAFE]: `<h6>Надежный пароль</h6><p>Пароль должен быть не менее 8 символов и содержать заглавные и строчные буквы, цифры и специальные символы</p>`,
            [IUserCard.PasswordStrength
                .SECURE]: `<h6>Надежный пароль</h6><p>Пароль должен быть не менее 8 символов и содержать заглавные и строчные буквы, цифры и специальные символы</p>`,
        };
        this.authTypes = IAuth.AuthMode;
        this.showError = false;
        this.steps = IAuthForOtherUser.RegistrationSteps;
        this.currentKeyCode = null;
        this.codeTime = 0;
        this.intervalId = 0;
        this.code = [
            {
                value: '',
            },
            {
                value: '',
            },
            {
                value: '',
            },
            {
                value: '',
            },
        ];
        this.newPassword = "";
        this.isShowModalPasswordSetted = false;
        this.blockedSendCodeTime = 30;
        this.isBlockedSendCode = false;
    }
    updated() {
        const { password, repeatPassword, newPassword } = this.$refs;
        if (password?.hasError || repeatPassword?.hasError || newPassword?.hasError) {
            this.someError = true;
        }
        else {
            this.someError = false;
        }
    }
    checkError(ref) {
        if (ref)
            return ref.hasError;
        else
            return false;
    }
    onScore({ strength }) {
        if (Object.keys(this.passwordStrengthText).includes(strength))
            this.passwordStrength = strength;
    }
    newPasswordValidate(val) {
        return val === this.newPassword || 'Пароли не совпадают';
    }
    get iconNameRepeatPass() {
        return this.checkError(this.$refs.repeatPassword) ? 'close-icon' : this.repeatPassword ? 'check-icon' : null;
    }
    get authType() {
        return this.$route.query.pageMode;
    }
    get userAccountInfo() {
        return this.$store.state.auth.userAccountInfo;
    }
    async codeInput(index, value) {
        if ((value || (this.currentKeyCode !== null && this.currentKeyCode === 8)) && this.code[index + 1]) {
            this.$refs[`input${index + 1}`][0].focus();
            setTimeout(() => {
                this.$refs[`input${index + 1}`][0].select();
            }, 0);
        }
        if (!(index === (this.code.length - 1))) {
            this.showError = false;
        }
        if (index === (this.code.length - 1) && value && !this.showError) {
            // this.isLoginedById ? this.confirmCode() : this.authUser(this.authType);
            const tempType = this.checkedValue.indexOf('@') >= 0 ? 'email' : 'phone';
            const res = await passwordCheckCode({
                [tempType]: this.checkedValue,
                code: this.convertCode(this.code)
            });
            if (res.status === 200) {
                this.showNewPasswordInputs = true;
            }
        }
    }
    async setNewPassword() {
        if (this.scenario === 'forgotPassword') {
            const resetData = {
                code: this.codeSms,
                password: this.newPassword
            };
            const loginData = {
                password: this.newPassword
            };
            if (this.phone) {
                resetData.phone = this.phone;
                loginData.phone = this.phone;
            }
            else if (this.email) {
                resetData.email = this.email;
                loginData.email = this.email;
            }
            const res = await passwordReset(resetData);
            if (res.status === 200) {
                const authRes = await this.$store.dispatch('auth/authUser', { authData: loginData, authType: 'login' });
                if (authRes)
                    this.$router.push({ name: 'indexPage' });
            }
        }
        else {
            const res = await changePatient({ password: this.newPassword });
            if (res) {
                this.isShowModalPasswordSetted = true;
            }
        }
    }
    setCharCode(e) {
        this.currentKeyCode = e.keyCode;
    }
    async confirmCode() {
        console.log('conf');
        const res = await confirmCode(this.setConfirmData());
        this.$store.dispatch('auth/authUser_new', res);
        if (res) {
            this.afterCodeConfirm(true);
        }
    }
    onFocus(index) {
        this.$refs[`input${index}`][0].select();
    }
    startCodeTimer(time) {
        this.codeTime = time;
        clearInterval(this.intervalId);
        this.intervalId = setInterval(() => {
            if (this.codeTime !== 0) {
                this.codeTime = --this.codeTime;
            }
            else {
                clearInterval(this.intervalId);
                this.sendNewCode();
            }
        }, 1000);
    }
    clearCode() {
        this.code.forEach(item => item.value = '');
        this.showError = false;
    }
    blockSendCode() {
        this.isBlockedSendCode = true;
        const isBlockedSendCodeTimeTimerId = setInterval(() => {
            this.blockedSendCodeTime -= 1;
            if (this.blockedSendCodeTime === 0) {
                this.isBlockedSendCode = false;
                this.blockedSendCodeTime = 30;
                clearInterval(isBlockedSendCodeTimeTimerId);
            }
        }, 1000);
    }
    sendNewCode() {
        // Костыль чтобы работал запрос
        const tempType = this.checkedValue.indexOf('@') >= 0 ? 'email' : 'phone';
        // 
        this.$store
            .dispatch('auth/sendCheckCode', { value: this.checkedValue, type: tempType })
            .then((res) => {
            if (res) {
                this.blockSendCode();
                // this.startCodeTimer(this.convertSendData(res, this.checkedValue).codeData.ttl)
            }
        });
    }
    authUser(authType) {
        console.log('auth');
        this.$store
            .dispatch('auth/authUser', { authData: this.setAuthData(), authType })
            .then((status) => this.afterCodeConfirm(status));
    }
    afterCodeConfirm(status) {
        if (status) {
            clearInterval(this.intervalId);
            if (this.authType === IAuth.AuthMode.REGISTRATION) {
                this.changeStep(this.steps.SET_PASSWORD);
            }
            else {
                this.$router.push({ name: ROUTE_NAME.INDEX_PAGE }).then(() => {
                    this.$q.notify({
                        message: 'Пожалуйста установите пароль в настройках профиля',
                    });
                });
            }
        }
        else {
            this.showError = true;
        }
    }
    setAuthData() {
        const data = {
            code: this.convertCode(),
            [this.getFiledType(this.checkedValue)]: this.checkedValue,
        };
        if (this.authType === IAuth.AuthMode.REGISTRATION) {
            data.name = this.$store.state.personalArea.patient.name;
            data.surname = this.$store.state.personalArea.patient.surname;
        }
        return data;
    }
    setConfirmData() {
        return {
            code: this.convertCode(),
            [this.getFiledType(this.checkedValue)]: this.checkedValue,
        };
    }
    convertCode() {
        return this.code.map((item) => item.value).join('');
    }
    destroy() {
        this.codeTime = 0;
        clearInterval(this.intervalId);
    }
};
__decorate([
    Prop({ default: 'phone' })
], StepSetPassword.prototype, "checkedValue", void 0);
__decorate([
    Prop({ default: false })
], StepSetPassword.prototype, "isLoginedById", void 0);
__decorate([
    Prop({ default: false })
], StepSetPassword.prototype, "onCodeSend", void 0);
__decorate([
    Prop()
], StepSetPassword.prototype, "phone", void 0);
__decorate([
    Prop()
], StepSetPassword.prototype, "email", void 0);
__decorate([
    Prop()
], StepSetPassword.prototype, "scenario", void 0);
__decorate([
    Prop()
], StepSetPassword.prototype, "codeSms", void 0);
StepSetPassword = __decorate([
    Component({
        components: {
            StatusIndicator,
            passwordMeter,
            ModalPasswordSetted,
        },
    })
], StepSetPassword);
export default StepSetPassword;
